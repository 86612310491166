:root {
  --primary: #f20505;
  --white: #ffffff;
  --black: #000000;
  --green:#0c7c0c;

      --alabaster: #fbfbfb;
    --black: #000000;
    --black-haze: #f6f6f6;
    --blue-chill: #118195;
    --boulder: #797979;
    --chicago: #5e5e5e;
    --cornflower: #a2d0ee;
    --eerie-black: #1d1d1d;
    --gravel: #474747;
    --gray: #909090;
    --gunsmoke: #848484;
    --gunsmoke-2: #858585;
    --mercury: #e4e4e4;
    --red: #f20505;
    --silver: #c0c0c0;
    --storm-dust: #636363;
    --tuatara: #343434;
    --tundora: #454545;
    --white: #ffffff;
    --font-size-l: 16.7px;
    --font-size-m: 16px;
    --font-size-s: 15px;
    --font-size-xl: 18px;
    --font-size-xs: 13.1px;
    --font-size-xxl: 22px;
    --font-size-xxs: 12.9px;
    --font-family-noto_sans: "Noto Sans", Helvetica;
    --font-family-roboto: "Roboto", Helvetica;
}
